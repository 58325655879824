import Checkbox from "@/components/Checkbox";
import { addRemove, sortBy, uniq } from "@/lib/array";
import rpc, { rpcTask } from "@/src/rpc";
import useCurrentOrg from "@/src/useCurrentOrg";
import classNames from "classnames";
import { useState, useEffect } from "react";
import PlusCircleIcon from "./icons/PlusCircleIcon";
import MinusCircleIcon from "./icons/MinusCircleIcon";


export default function SetupCategories({catalogs, useLoading, redirectAfter, compact, readonly, inSignup, onClose}) {


  const org = useCurrentOrg()

  const [groups, setGroups] = useState([])
  const [selectedItemIds, setSelectedItemIds] = useState([])
  const [changed, setChanged] = useState(false)

  const buttonDisabled = !inSignup && (!changed || selectedItemIds.length === 0)
  const buttonLabel = inSignup ? 'Continue' : 'Rebuild Calendar'


  function isSelected (item) {
    return selectedItemIds.includes(item.id)
  }


  function toggleCategory (item) {
    setChanged(true)

    setSelectedItemIds(addRemove(selectedItemIds, item.id))
  }


  function selectGroup(group, selected) {
    setChanged(true)

    const itemIds = group.items.map(c => c.id)

    if (selected) {
      setSelectedItemIds(ids => uniq([...ids, ...itemIds]))
    } else {
      setSelectedItemIds(ids => ids.filter(id => !itemIds.includes(id)))
    }
  }


  useEffect(() => {
    if (!catalogs) return

    const groupList = []
    const enabledItemIds = []

    // We need to hide any catalogs that are not enabled
    let enabledCatalogs = catalogs.filter(c => c.enabled)

    // Put the Essentials catalog first so any ungrouped categories from other
    // catalogs can get merged into the Essentials (e.g. "Holidays")
    enabledCatalogs = sortBy(enabledCatalogs, c => c.name === 'Essentials' ? 0 : 1)

    // Create a group for each of the catalogs
    enabledCatalogs.forEach(catalog => {
      groupList.push({
        id:        groupList.length + 1,
        name:      catalog.name,
        isCatalog: true,
        items:     [],
      })
    })

    enabledCatalogs.forEach(catalog => {
      catalog.categories.forEach(category => {
        let group = groupList.find(g => g.name === catalog.name && g.isCatalog)
        let categoryName = category.name

        // Categories can be put into explicit groups by using a dash in the name
        const categoryHasGroupPrefix = category.name.includes(' - ')
        const groupName = categoryHasGroupPrefix ? category.name.split(' - ')[0] : ''

        if (groupName) {
          categoryName = categoryName.replace(groupName + ' - ', '')

          group = groupList.find(g => g.name === groupName)

          if (!group) {
            group = {
              id:    groupList.length + 1,
              name:  groupName,
              items: [],
            }
            groupList.push(group)
          }
        }

        // Check to see if there is already a category in the group with the same name
        let item = group.items.find(c => c.name === categoryName)
        let itemId = [group.id, group.items.length + 1].join('-')

        if (!item) {
          item = {
            id:     itemId,
            name:   categoryName,
            topics: [],
          }
          group.items.push(item)
        }

        // Add this category to the group
        item.topics.push({
          catalogId:  catalog.id,
          categoryId: category.id,
        })

        // Enable the group if this category
        if (category.enabled && !enabledItemIds.includes(itemId)) {
          enabledItemIds.push(itemId)
        }
      })
    })

    // Prune out any groups that don't have any items
    let visibleGroups = groupList.filter(g => g.items.length > 0)

    // Sort the Essentials to the top
    visibleGroups = sortBy(visibleGroups, g => {
      if (g.name === 'Essentials' && g.isCatalog) return ''
      return g.name
    })

    setGroups(visibleGroups)
    setSelectedItemIds(enabledItemIds)

  }, [catalogs])


  async function nextStep () {
    const wrapper = redirectAfter ? redirectAfter : fun => fun()
    wrapper(submitSelections)
  }


  async function submitSelections () {
    const selectedTopics = groups.flatMap(g => g.items).filter(isSelected).flatMap(i => i.topics)
    const catalogIds = uniq(catalogs.map(t => t.id))

    const selections = catalogIds.map(catalogId => ({
      catalogId,
      categoryIds: selectedTopics.filter(t => t.catalogId === catalogId).map(t => t.categoryId),
    }))

    const task = await rpcTask('signup.subscribeToCatalogs', {selections})

    if (inSignup) {
      const res = await rpc('signup.gotoNextStep')
      if (res && res.success) {
        return res.redirect
      }
    } else {
      onClose(task)
    }
  }


  if (useLoading(catalogs && org)) return null

  const wideLayout = groups.length > 1 && !compact
  const showButton = !readonly

  return <>
    <div className="px-4 pt-4 pb-5 bg-f3f3f3 text-center">
      <div className="content mx-2">
        { !compact &&
          <div className="mb-5">
            { org?.aiEnabled ?
              <div className={classNames("text-left mx-2", {"max-width-300": groups.length === 1})}>
                <h1 className="text-center">
                  Other Topics
                </h1>
                <p className="gray">
                  In addition to posts about your business, what else do you
                  want to post about?
                </p>
              </div>
            : org?.aiContentNeeded ?
              <>
                <p className="gray">
                  We&apos;ll create social media posts for you, based on your website.
                </p>
                <h1>What else do you want to post about?</h1>
              </>
            :
              <h1>Which topics do you want to post about?</h1>
            }
          </div>
        }

        { (readonly && !selectedItemIds.length) &&
          <p className="gray">
            No topics selected.
          </p>
        }

        <div className={classNames("column-layout my-4", {'wide-layout': wideLayout})}>
          { groups.map(group => {
            const allSelected = group.items.every(isSelected)
            const noneSelected = group.items.every(c => !isSelected(c))
            if (readonly && noneSelected) return null

            return (
              <div className="avoid-column-break pb-1" key={group.id}>

                <div className="text-left category-group bg-white border border-gray px-4 py-1 rounded-lg shadow mb-5">
                  <div className="flex-row spread border-bottom my-1 pb-1">
                    <div className={"gray italics py-1"}>
                      {group.name}
                    </div>
                    { !readonly &&
                      <div className="text-sm flex-row gap-1">
                        <div title="Select All" href="#" className={classNames({link: !allSelected, pointer: !allSelected, "light-gray": allSelected, "cursor-not-allowed": allSelected})} onClick={() => selectGroup(group, true)}>
                          <PlusCircleIcon/>
                        </div>
                        <div title="Select None" href="#" className={classNames({link: !noneSelected, pointer: !noneSelected, "light-gray": noneSelected, "cursor-not-allowed": noneSelected})} onClick={() => selectGroup(group, false)}>
                          <MinusCircleIcon/>
                        </div>
                      </div>
                    }
                  </div>

                  <div className='py-1 two-column'>
                    { sortBy(group.items, 'name').map(groupCat => {
                      const checked = isSelected(groupCat)
                      if (readonly && !checked) return null
                      if (readonly) {
                        return (
                          <div key={groupCat.id} className="px-1 flex-row flex-align-start gap-1">
                            <span className="">•</span>
                            <span>{groupCat.name}</span>
                          </div>
                        )
                      }
                      return (
                        <div className={classNames("avoid-column-break category-checkbox")} key={groupCat.id}>
                          <Checkbox
                            alignTop
                            checked={checked}
                            onChange={() => toggleCategory(groupCat)}
                          >
                            <span className={classNames({ gray: !checked, strike: !checked})}>
                              {groupCat.name}
                            </span>
                          </Checkbox>
                          <div className="topic-ids tiny gray">
                            {JSON.stringify(groupCat.topics)}
                          </div>
                        </div>
                      )
                    })}
                  </div>

                </div>
              </div>
            )
          })}

        </div>

        { showButton &&
          <div className="flex-center flex-row gap-2 mb-2">
            <button className="button is-primary" onClick={nextStep} disabled={buttonDisabled}>
              {buttonLabel}
            </button>
            { !inSignup &&
              <button className="button" onClick={() => onClose()}>
                Cancel
              </button>
            }
          </div>
        }

      </div>
    </div>

    <style jsx>{`
      @media screen and ( min-width: 1200px ) {
        .wide-layout {
          width: 90vw;
        }
      }
      .column-layout {
        column-width: 30rem;
        gap: 2em;
        text-align: left;
      }
      .two-column {
        column-count: 2;
        column-width: 12rem;
      }
      .avoid-column-break {
        break-inside: avoid;
      }
      .catalog-name {
        font-size: 1.2rem;
        font-weight: bold;
      }
      .category-checkbox {
        padding: 0.25rem 0;
      }
      .note {
        margin: 1rem 0;
        font-size: 0.9rem;
      }
      .topic-ids {
        display: none;
      }
    `}</style>

  </>
}
